export const codeMessage = {
    200: 'The server successfully returned the requested data.',
    201: 'New or modified data is successful.',
    202: 'A request has entered the background queue (asynchronous task).',
    204: 'Data deleted successfully.',
    400: 'There was an error in the request sent, and the server did not create or modify data.',
    401: 'The user does not have permission (the token, username, password is wrong).',
    403: 'The user is authorized, but access is forbidden.',
    404: 'The request sent was for a record that did not exist, and the server did not operate.',
    406: 'The requested format is not available.',
    410: 'The requested resource is permanently deleted and will no longer be available.',
    422: 'When creating an object, a validation error occurred.',
    500: 'An error occurred in the server, please check the server.',
    502: 'Gateway error.',
    503: 'The service is unavailable, the server is temporarily overloaded or maintained.',
    504: 'The gateway timed out.',
  };