/* eslint-disable consistent-return */
import { message } from "antd";
import axios from "axios";
import { codeMessage } from "../utils/request";

const setAxiosInterceptors = () => {
  // Add a response interceptor
  axios.interceptors.response.use(
    (response) => {
      if (
        response?.data &&
        typeof response.data.data !== "undefined" &&
        (response?.data?.status === "Success" ||
          response?.data?.status === 200 ||
          response?.data?.status === 201)
      ) {
        return response;
      }
    },

    (error) => {
      const {
        response,
        request,
        config: { override },
      } = error;
      if (override) {
        if (override.onError) {
          override.onError(error);
        }
        return Promise.reject(error);
      }
      if (response) {
        const { data } = response;
        if (data) {
          const { error, status } = data;
          // Showing error messages
          if (!error || !Object.keys(error)?.length) {
            message.error(data?.message);
          } else if (status !== "Not Found") {
            if (error?.detail) {
              message.error(error?.detail);
            } else if (error?.details) {
              message.error(error?.details[0]?.message);
            } else {
              message.error(error?.message || data?.error);
            }
          } else {
            message.error(data?.message);
          }
        }

        const { status } = response;

        console.log("Error code:", status);
        // Uncomment and use the following code block if you want to do something based on status of the error.
        if (status === 401) {
        } else if (status === 500) {
          message.error(codeMessage[status]);
        } else if (status === 403) {
          // message.error(codeMessage[status]);
        } else if (status === 404) {
          // message.error(codeMessage[status]);
        }
      }

      if (request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js

        // Handling timeout error
        if (error.code === "ECONNABORTED") {
          // Passing customer message because the original (error.message) is "timeout of (timeout_number)ms exceeded".

          message.error(
            "Request timeout error, please try again or refresh the page."
          );
        } else {
          // Handling other errors like "Network Error"
          // message.error(error.message);
        }
      } else {
        // Something happened in setting up the request that triggered an Error, like "Invalid url"
        console.log(error.message);
      }

      return Promise.reject(error);
    }
  );
};

export default setAxiosInterceptors;
