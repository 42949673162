// libs
import { Spin } from "antd";
import React, { lazy, Suspense, useContext } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
  Navigate,
} from "react-router-dom";

import UserContext from "../context/UserContext";

const Users = lazy(() => import("../features/Users"));
const BrokerMonths = lazy(() => import("../features/BrokerMonths"));
const CommissionPayout = lazy(() => import("../features/CommissionPayout"));
const Dashboard = lazy(() => import("../features/Dashboard"));
const Login = lazy(() => import("../features/Login"));
const PageNotFound = lazy(() => import("../features/PageNotFound"));
const RegisteredReps = lazy(() => import("../features/RegisteredReps"));
const Reports = lazy(() => import("../features/Reports"));
const Trades = lazy(() => import("../features/Trades"));
const Trailers = lazy(() => import("../features/Trailers"));
const TransactionTypes = lazy(() => import("../features/TransactionTypes"));

function RequireAuth({ children }) {
  const { auth } = useContext(UserContext);
  let location = useLocation();
  if (auth?.user) {
    return <>{children}</>;
  }
  return <Navigate to="/login" state={{ from: location }} replace />;
}

const ApplicationRoutes = () => {
  const { auth, setAuth } = useContext(UserContext);
  return (
    <Router>
      <Suspense
        fallback={
          <Spin
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              height: "100vh",
            }}
            spinning={true}
          />
        }
      >
        <Routes>
          <Route path="/" element={<Navigate to="/dashboard" replace />} />
          <Route
            path="/login"
            element={<Login auth={auth} setAuth={setAuth} />}
          />
          <Route
            path="/dashboard"
            element={
              <RequireAuth>
                <Dashboard auth={auth} />
              </RequireAuth>
            }
          />
          <Route
            path="/reports/:id"
            element={
              <RequireAuth>
                <Reports />
              </RequireAuth>
            }
          />
          <Route
            path="/registered-reps"
            element={
              <RequireAuth>
                <RegisteredReps />
              </RequireAuth>
            }
          />
          {auth?.user?.role === "super_admin" && (
            <Route
              path="/users"
              element={
                <RequireAuth>
                  <Users />
                </RequireAuth>
              }
            />
          )}
          <Route
            path="/transaction-types"
            element={
              <RequireAuth>
                <TransactionTypes />
              </RequireAuth>
            }
          />
          <Route
            path="/broker-months"
            element={
              <RequireAuth>
                <BrokerMonths />
              </RequireAuth>
            }
          />

          <Route
            path="/commission-payout"
            element={
              <RequireAuth>
                <CommissionPayout />
              </RequireAuth>
            }
          />
          <Route
            path="/trades"
            element={
              <RequireAuth>
                <Trades />
              </RequireAuth>
            }
          />
          <Route
            path="/trailers"
            element={
              <RequireAuth>
                <Trailers />
              </RequireAuth>
            }
          />
          {/* <Route
          path="/direct-deposit-commissions"
          element={
            <RequireAuth>
              <DirectDepositCommissions />
            </RequireAuth>
          }
        /> */}

          <Route path="/404" element={<PageNotFound />} />
          <Route path="*" element={<Navigate to="/404" replace />} />
        </Routes>
      </Suspense>
    </Router>
  );
};
export default ApplicationRoutes;
