import React, { useEffect } from "react";
import { ConfigProvider, message } from "antd";
import { SWRConfig } from "swr";
import { gapi } from "gapi-script";

import ApplicationRoutes from "./config/Routes";
import usePersistentState from "./hooks/usePersistentState";
import UserContext from "./context/UserContext";
import { fetcher } from "./utils/fetcher";
import { theme } from "./utils/theme";
import initAxiosGlobalConfigs from "./config/axiosConfig";
import laggy from "./middlewares/laggy";

const App = () => {
  const [auth, setAuth] = usePersistentState("auth", {
    initialValue: {},
  });

  initAxiosGlobalConfigs(auth?.token);

  useEffect(() => {
    if (auth?.user && auth?.token) {
      // set token from local storage
      initAxiosGlobalConfigs(auth?.token);
    }
  }, [auth]);

  useEffect(() => {
    function start() {
      gapi.client.init({
        clientId: process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID,
        scope: "",
      });
    }

    gapi.load('client:auth2', start);
  }, []);

  return (
    <SWRConfig
      value={{
        fetcher: fetcher,
        onError: (error, key) => {
          if (error) {
            // TODO:  Add Proper error handling in this middleware
            message.error("Internal server Error!", error);
          }
        },
        use: [laggy],
      }}
    >
      <UserContext.Provider value={{ auth, setAuth }}>
        <ConfigProvider theme={theme}>
          <ApplicationRoutes />
        </ConfigProvider>
      </UserContext.Provider>
    </SWRConfig>
  );
};

export default App;
